//Ant Design Less Variables
@alert-error-background-color: #cc1f361a;
@alert-error-border-color: #a61129;
@alert-error-icon-color: rgba(204, 31, 54, 0.8);
@alert-info-background-color: #61c8d71a;
@alert-info-border-color: #469eb0;
@alert-info-icon-color: #61c8d7;
@alert-success-background-color: #10a64a1a;
@alert-success-border-color: #068039;
@alert-success-icon-color: rgba(16, 166, 74, 0.8);
@alert-warning-background-color: #ffcc331a;
@alert-warning-border-color: #d9a521;
@alert-warning-icon-color: #ffcc33;
@background-color-base: #f5f5f5;
@border-color-base: #d5d0da;
@border-radius-base: 2px;
@boxShadow12: 0 1px 4px rgba(0, 0, 0, 0.12);
@breadcrumb-font-size: @font-size-sm;
@breadcrumb-icon-font-size: @font-size-sm;
@btn-danger-bg: #d11d5c;
@btn-danger-border: #d11d5c;
@btn-danger-color: #fff;
@btn-disable-bg: @disabled-bg;
@btn-disable-color: #333333;
@btn-border-radius-base: 8px;
@btn-font-size-lg: @font-size-lg;
@btn-font-size-sm: @font-size-base;
@btn-font-size: @font-size-base+2px;
@btn-font-weight: 400;
@btn-group-border: #61c8d7;
@btn-primary-bg: #3f4373;
@btn-primary-border: #3f4373;
@btn-primary-color: #fff;
@checkbox-color: @primary-color;
@disabled-bg: #f5f5f5;
@disabled-color: rgba(51, 51, 51, 0.3);
@error-color: #cc1f36;
@font-family: 'Libre Franklin', sans-serif;
@font-size-base: 14px;
@font-size-lg: @font-size-base + 4px;
@font-size-sm: 12px;
@form-item-trailing-colon: false;
@heading-color: rgba(0, 0, 0);
@heading-1-size: ceil(@font-size-base*2.29);
@heading-2-size: ceil(@font-size-base*1.71);
@heading-3-size: ceil(@font-size-base*1.43);
@heading-4-size: ceil(@font-size-base*1.14);
@heading-5-size: ceil(@font-size-base*1.05);
@highlight-color: #ed693a;
@info-color: #61c8d7;
@input-placeholder-color: rgba(0, 0, 0, 0.38);
@label-color: rgb(0, 0, 0);
@label-required-color: #cc1f36;
@layout-body-background: #f0f2f5;
@layout-header-background: @primary-color;
@link-color: @primary-color;
@link-active-color: @primary-color;
@link-hover-color: @primary-5; //default
@menu-bg: #fff;
@menu-item-color: @primary-color;
@menu-item-selected-color: #fff;
@menu-dark-bg: @primary-color;
@menu-dark-danger-color: #cc1f36;
//@menu-dark-item-active-bg: rgba(97, 199, 215, 0.6);
@menu-dark-item-active-bg: @primary-color;
@menu-dark-item-active-danger-bg: #cc1f369f;
//@menu-dark-item-selected-bg: #61c7d7;
@menu-dark-item-selected-bg: @primary-color;
// @menu-highlight-color: #469eb0;
@menu-highlight-color: #fff;
@menu-highlight-danger-color: #cc1f36;
// @menu-item-active-bg: rgba(97, 200, 215, .1);
@menu-item-active-bg: @primary-color;
@menu-item-active-danger-bg: rgba(204, 31, 54, 0.3);
@menu-item-font-size: @font-size-base;
@menu-item-font-weight: 600;
@normal-color: #d9d9d9;
@outline-color: #61c8d7;
@Primary-1: #3f43734d;
@primary-5: #5f65a7;
@primary-6: @primary-color;
@primary-7: #20223a;
@primary-color: #3f4373;
@processing-color: @primary-color;
@radio-button-hover-color: #5f65a7;
@radio-disabled-button-checked-bg: #d5d0da;
@radio-dot-color: @primary-color;
@rate-star-color: #ffbf00;
@select-dropdown-bg: #fff;
@select-item-selected-bg: rgba(97, 200, 215, 0.2);
@select-item-active-bg: rgba(97, 200, 215, 0.07);
@success-color: #10a64a;
@tabs-horizontal-padding-lg: 12px 16px;
@text-color-secondary: rgba(0, 0, 0, 0.99);
@text-color: rgb(0, 0, 0);
@warning-color: #ffcc33;

//Non Antdesign Less Variables
@heading-1-font-family: 'Poppins', sans-serif;
@heading-2-font-family: 'Libre Franklin', sans-serif;
@heading-3-font-family: 'Libre Franklin', sans-serif;
@heading-4-font-family: 'Libre Franklin', sans-serif;
@heading-5-font-family: 'Libre Franklin', sans-serif;
@body-font-family: @font-family;

@heading-1-font-weight: 600;
@heading-2-font-weight: 600;
@heading-3-font-weight: 500;
@heading-4-font-weight: 600;
@heading-5-font-weight: 400;
@body-font-weight: 400;

@heading-1-font-color: rgb(51, 51, 51);
@heading-2-font-color: rgb(51, 51, 51);
@heading-3-font-color: rgb(51, 51, 51);
@heading-4-font-color: rgb(51, 51, 51);
@heading-5-font-color: rgb(51, 51, 51);
@body-font-color: @text-color;

//CSS Overrides
h1.ant-typography {
  font-family: @heading-1-font-family;
  font-weight: @heading-1-font-weight;
  color: @heading-1-font-color;
}

h2.ant-typography {
  font-family: @heading-2-font-family;
  font-weight: @heading-2-font-weight;
  color: @heading-2-font-color;
}

h3.ant-typography {
  font-family: @heading-3-font-family;
  font-weight: @heading-3-font-weight;
  color: @heading-3-font-color;
}

h4.ant-typography {
  font-family: @heading-4-font-family;
  font-weight: @heading-4-font-weight;
  color: @heading-4-font-color;
}

h5.ant-typography {
  font-family: @heading-5-font-family;
  font-weight: @heading-5-font-weight;
  color: @heading-5-font-color;
}

body {
  font-weight: @body-font-weight;
  font-family: @body-font-family;
  color: @body-font-color;
}

div.ant-result-title {
  font-size: @heading-3-size;
  font-weight: @heading-3-font-weight;
  font-family: @heading-3-font-family;
  color: @heading-3-font-color;
}

.ant-result-error .ant-result-icon > .anticon {
  color: rgba(204, 31, 54, 0.8);
}

.ant-result-success .ant-result-icon > .anticon {
  color: rgb(9, 125, 90);
}

.ant-divider-horizontal.ant-divider-with-text .ant-typography {
  color: #333333;
  font-weight: 500;
  font-size: 14px;
}

.ant-form-item-label > label .ant-typography {
  color: #333333;
}

//override to fix the issue with new font unbolding headers
thead[class*='ant-table-thead'] th {
  font-weight: @body-font-weight+200 !important;
  color: #333333;
}

a.ant-typography {
  text-decoration: underline;
  transition: all 0.4s;
}

a.ant-typography:hover {
  text-decoration: underline;
}

.ant-menu-horizontal > .ant-menu-item {
  font-weight: 600;
}

span.ant-menu-title-content {
  padding: 20px 20px;
}
li.ant-menu-overflow-item.ant-menu-item.ant-menu-item-only-child {
  padding: 0px 0px;
}

span.ant-menu-title-content::after {
  content: '';
  position: absolute;
  display: block;
  width: 100%;
  height: 2px;
  bottom: 10px;
  left: 0;
  background-color: #fff;
  transform: scaleX(0);
  transition: transform 0.3s ease;
}
span.ant-menu-title-content:hover::after {
  transform: scaleX(0.9);
}

li.ant-menu-overflow-item.ant-menu-item.ant-menu-item-selected.ant-menu-item-only-child::after {
  content: '';
  position: absolute;
  display: block;
  width: 100%;
  height: 2px;
  bottom: 10px;
  left: 0;
  background-color: #fff;
  transform: scaleX(0.9);
}

// increase border of cards
.ant-card-bordered {
  border: 2px solid #f0f0f0;
}

// add border to small buttons
.ant-btn-sm {
  border-radius: @btn-border-radius-base - 2;
}

.ant-divider-plain.ant-divider-with-text b {
  font-weight: 500;
}

li.ant-menu-item-selected {
  font-weight: 500;
}

table:not(.not-striped) tr:nth-child(2n):not(.ant-descriptions-row) td {
  background-color: #fafafa;
}
.ant-table-thead tr th {
  background-color: #f8efe3;
}
