html,
body {
  height: 100%;
}

.ant-layout.layout {
  min-height: 100vh;
}

@media only screen and (max-width: 768px) {
  header.ant-layout-header {
    padding-left: 25px;
    padding-right: 25px;
  }
}
.site-layout {
  padding: 0 50px;
  margin-top: 64px;
}

.logo {
  margin: 7px 0px;
  float: left;
  height: 50px;
}

.header-logo {
  background: #fff !important;
  min-height: 150px;
  /* max-height: 225px; */
}

.site-layout .site-layout-background {
  background: #fff;
  min-height: 40vh;
  padding: 24px;
}

.wrappable-antradio-item {
  white-space: normal;
}

.ecfmg-small-descriptions .ant-descriptions-item-label {
  font-size: 10px;
  line-height: normal;
  padding-bottom: 3px;
}

.ecfmg-small-descriptions .ant-descriptions-item-content {
  line-height: normal;
  overflow-wrap: anywhere;
}

.ecfmg-small-descriptions .ant-descriptions-row > th {
  padding-bottom: 0px;
}

.ecfmg-small-multiline-select .ant-select-item-option-content {
  flex: auto;
  overflow: normal;
  white-space: pre-wrap;
  text-overflow: inherit;
  text-indent: 5px hanging each-line;
}

.ecfmg-small-multiline-select p:nth-child(n + 2) {
  -webkit-text-indent: 5px;
  text-indent: 5px;
}

.loginForm {
  text-align: right;
  max-width: 400px;
  display: inline-block;
}
.loginElement {
  width: 180px;
}

.authorityCard {
  width: 200px;
}
@media only screen and (min-width: 768px) {
  .authorityCard {
    width: 350px;
  }
}

@media only screen and (max-width: 768px) {
  .site-layout {
    padding: 0 25px;
  }
}

.ant-input-group-addon {
  width: 120px;
  text-align: right;
}

.space-align-container {
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
}

.space-align-block {
  margin: 8px 4px;
  border: 1px solid #40a9ff;
  padding: 4px;
  flex: none;
}

@media only screen and (min-width: 768px) {
  .alignRightMedium {
    display: flex;
    justify-content: flex-end;
  }
}

.ant-card-meta-avatar {
  font-size: x-large;
  margin-right: 5px;
}

/* change color of Clear button on TextArea */
.ant-input-affix-wrapper-textarea-with-clear-btn .ant-input-clear-icon {
  color: #d11d5c;
}

/* restrict max width of error message on add encounter modal */
.ant-message-notice-content {
  max-width: 55vw;
}
